<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item prop="id" v-if="formType === 1">
        <span slot="label" >
          <a-tooltip>
            {{$t('bgm读取.id')}}<template slot="title">{{$t('bgm读取.id')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.id"  :placeholder="$t('通用.输入.请输入')+$t('bgm读取.id')" />
      </a-form-model-item>
      <a-form-model-item prop="uuid" >
        <span slot="label" >
          <a-tooltip>
            {{$t('bgm读取.本会话的唯一ID')}}<template slot="title">{{$t('bgm读取.本会话的唯一ID')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.uuid" :maxLength="64" :placeholder="$t('通用.输入.请输入')+$t('bgm读取.本会话的唯一ID')" />
      </a-form-model-item>
      <a-form-model-item prop="recordedAt" >
        <span slot="label" >
          <a-tooltip>
            {{$t('bgm读取.记录时间')}}<template slot="title">{{$t('bgm读取.记录时间')}}</template>
          </a-tooltip>
        </span>
        <a-date-picker style="width: 100%" v-model="form.recordedAt" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item prop="recordedAtUtcOffset" >
        <span slot="label" >
          <a-tooltip>
            {{$t('bgm读取.记录偏移时间')}}<template slot="title">{{$t('bgm读取.记录偏移时间')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.recordedAtUtcOffset"  :placeholder="$t('通用.输入.请输入')+$t('bgm读取.记录偏移时间')" />
      </a-form-model-item>
      <a-form-model-item prop="glucoseConcentration" >
        <span slot="label" >
          <a-tooltip>
            {{$t('bgm读取.血糖值')}}<template slot="title">{{$t('bgm读取.血糖值')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.glucoseConcentration"  :placeholder="$t('通用.输入.请输入')+$t('bgm读取.血糖值')" />
      </a-form-model-item>
      <a-form-model-item prop="sequenceNumber" >
        <span slot="label" >
          <a-tooltip>
            {{$t('bgm读取.自增序列号')}}<template slot="title">{{$t('bgm读取.自增序列号')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.sequenceNumber"  :placeholder="$t('通用.输入.请输入')+$t('bgm读取.自增序列号')" />
      </a-form-model-item>
      <a-form-model-item prop="serialNumber" >
        <span slot="label" >
          <a-tooltip>
            {{$t('bgm读取.传感器ID')}}<template slot="title">{{$t('bgm读取.传感器ID')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.serialNumber" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('bgm读取.传感器ID')" />
      </a-form-model-item>
      <a-form-model-item prop="sessionUuid" >
        <span slot="label" >
          <a-tooltip>
            {{$t('bgm读取.会话唯一ID，外键')}}<template slot="title">{{$t('bgm读取.会话唯一ID，外键')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.sessionUuid" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('bgm读取.会话唯一ID，外键')" />
      </a-form-model-item>
      <a-form-model-item prop="controlSolution" >
        <span slot="label" >
          <a-tooltip>
            {{$t('bgm读取.control_solution')}}<template slot="title">{{$t('bgm读取.control_solution')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.controlSolution"  :placeholder="$t('通用.输入.请输入')+$t('bgm读取.control_solution')" />
      </a-form-model-item>
      <a-form-model-item prop="crc" >
        <span slot="label" >
          <a-tooltip>
            {{$t('bgm读取.crc')}}<template slot="title">{{$t('bgm读取.crc')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.crc"  :placeholder="$t('通用.输入.请输入')+$t('bgm读取.crc')" />
      </a-form-model-item>
      <a-form-model-item prop="activeCgmDeviceSerialNumbers" >
        <span slot="label" >
          <a-tooltip>
            {{$t('bgm读取.serialNumber  数组的值')}}<template slot="title">{{$t('bgm读取.serialNumber  数组的值')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.activeCgmDeviceSerialNumbers" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item prop="remark" >
        <span slot="label" >
          <a-tooltip>
            {{$t('bgm读取.备注')}}<template slot="title">{{$t('bgm读取.备注')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.remark" :maxLength="100" :placeholder="$t('通用.输入.请输入')+$t('bgm读取.备注')" />
      </a-form-model-item>
      <a-form-model-item prop="systemIdentifier" >
        <span slot="label" >
          <a-tooltip>
            {{$t('bgm读取.手机设备UUID')}}<template slot="title">{{$t('bgm读取.手机设备UUID')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.systemIdentifier" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('bgm读取.手机设备UUID')" />
      </a-form-model-item>
      <a-form-model-item prop="systemModel" >
        <span slot="label" >
          <a-tooltip>
            {{$t('bgm读取.手机型号')}}<template slot="title">{{$t('bgm读取.手机型号')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.systemModel" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('bgm读取.手机型号')" />
      </a-form-model-item>
      <a-form-model-item prop="systemName" >
        <span slot="label" >
          <a-tooltip>
            {{$t('bgm读取.手机名字')}}<template slot="title">{{$t('bgm读取.手机名字')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.systemName" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('bgm读取.手机名字')" />
      </a-form-model-item>
      <a-form-model-item prop="systemVersion" >
        <span slot="label" >
          <a-tooltip>
            {{$t('bgm读取.系统版本')}}<template slot="title">{{$t('bgm读取.系统版本')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.systemVersion" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('bgm读取.系统版本')" />
      </a-form-model-item>
      <a-form-model-item prop="appIdentifier" >
        <span slot="label" >
          <a-tooltip>
            {{$t('bgm读取.包名')}}<template slot="title">{{$t('bgm读取.包名')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.appIdentifier" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('bgm读取.包名')" />
      </a-form-model-item>
      <a-form-model-item prop="appVersion" >
        <span slot="label" >
          <a-tooltip>
            {{$t('bgm读取.版本')}}<template slot="title">{{$t('bgm读取.版本')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.appVersion" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('bgm读取.版本')" />
      </a-form-model-item>
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getBgmReading, addBgmReading, updateBgmReading } from '@/api/bgmReading/bgmReading'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from "@/api/tool/oss";


export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        id: null,

        uuid: null,

        recordedAt: null,

        recordedAtUtcOffset: null,

        glucoseConcentration: null,

        sequenceNumber: null,

        serialNumber: null,

        sessionUuid: null,

        controlSolution: null,

        crc: null,

        activeCgmDeviceSerialNumbers: null,

        createTime: null,

        remark: null,

        systemIdentifier: null,

        systemModel: null,

        systemName: null,

        systemVersion: null,

        appIdentifier: null,

        appVersion: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        id: [
          { required: true, message: this.$t('bgm读取.id')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        uuid: null,
        recordedAt: null,
        recordedAtUtcOffset: null,
        glucoseConcentration: null,
        sequenceNumber: null,
        serialNumber: null,
        sessionUuid: null,
        controlSolution: null,
        crc: null,
        activeCgmDeviceSerialNumbers: null,
        createTime: null,
        remark: null,
        systemIdentifier: null,
        systemModel: null,
        systemName: null,
        systemVersion: null,
        appIdentifier: null,
        appVersion: null
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getBgmReading({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateBgmReading(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addBgmReading(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
