import request from '@/utils/request'


// 查询bgm读取列表
export function listBgmReading(query) {
  return request({
    url: '/bgmReading/bgmReading/list',
    method: 'get',
    params: query
  })
}

// 查询bgm读取分页
export function pageBgmReading(query) {
  return request({
    url: '/bgmReading/bgmReading/page',
    method: 'get',
    params: query
  })
}

// 查询bgm读取详细
export function getBgmReading(data) {
  return request({
    url: '/bgmReading/bgmReading/detail',
    method: 'get',
    params: data
  })
}

// 新增bgm读取
export function addBgmReading(data) {
  return request({
    url: '/bgmReading/bgmReading/add',
    method: 'post',
    data: data
  })
}

// 修改bgm读取
export function updateBgmReading(data) {
  return request({
    url: '/bgmReading/bgmReading/edit',
    method: 'post',
    data: data
  })
}

// 删除bgm读取
export function delBgmReading(data) {
  return request({
    url: '/bgmReading/bgmReading/delete',
    method: 'post',
    data: data
  })
}
